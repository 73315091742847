


// 服务器地址
export const sever_url = 'https://cp.baotou.rp.24xsjj.com'

// 资源服务器地址
export const qiniu_url = 'https://cp.baotou.rp.24xsjj.com'

//IMSDK
export const imsdk=1400751491;